import React, { useState, useEffect } from 'react';
import './App.css';

import Signature from './components/Signature';

function App() {
  const [ offsetA, setOffsetA ] = useState(2200);
  const [ offsetB, setOffsetB ] = useState(700);
  const [ offsetC, setOffsetC ] = useState(700);

  const [ speed, setSpeed ] = useState(3);

  let interval: NodeJS.Timer;

  useEffect(() => {
    startTimer()

    return () => {
      clearInterval(interval);
    }
  }, [offsetA, offsetB, offsetC]);

  const startTimer = () => {
    interval = setInterval(() => {
      if (offsetA >= 0) {
        setOffsetA(offsetA - speed);
      }
      else if (offsetB >= 0) {
        setOffsetB(offsetB - speed);
      }
      else if (offsetC >= 0) {
        setOffsetC(offsetC - speed);
      }
      else {
        console.log(offsetA, offsetB, offsetC)
        clearInterval(interval);
      }
    }, 0);
  }

  return (
    <div className="App">
      <Signature
        strokeColor='#61DBFB'
        strokeWidth={3}
        strokeOffsets={[
          offsetA < 0 ? 0 : offsetA, 
          offsetB < 0 ? 0 : offsetB, 
          offsetC < 0 ? 0 : offsetC
        ]}
        strokeDashArray={[2200, 700, 700]}
      />
      <label style={{ fontSize: 20 }}>{speed}</label>
      <input type="range" min="1" max="10" value={speed} onChange={(e) => setSpeed(parseInt(e.target.value))}></input>
      <button onClick={() => {
        clearInterval(interval);
        setOffsetA(2200);
        setOffsetB(700);
        setOffsetC(700);
        startTimer();
      }} style={{ marginTop: 20 }}>Replay</button>
    </div>
  );
}

export default App;
